import * as React from "react"


import Layout from "../components/layout"
import Seo from "../components/seo"
import { useLocation } from "@reach/router"

import Form from "../components/ContactForms/RemoveEmail"
import { deCH } from "../lang/de-CH"

import { Helmet } from "react-helmet"

export default function Home() {
  const location = useLocation()

  const country = "CH"
  const lang = "de-CH"
  const txt = deCH.about

  return (
    <Layout location={country} path={location.pathname} language={lang}>
      <Seo
        title="DoryGo - Anfragen"
        description="Ich möchte beratet werden"
        lang={lang}
      />

      <Form />

    </Layout>
  )
}
